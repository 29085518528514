@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

body {
  margin: 0;
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 16px;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --wired-toggle-on-color: darkorange;
}
